import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input } from 'components/primitives/form';

const PhoneNumberField = ({ validation, ...attributes }) => (
  <Field
    type="tel"
    as={Input}
    validation={{ ...validation, phoneNumber: true }}
    {...attributes}
  />
);

PhoneNumberField.propTypes = fieldPropTypes;

export default PhoneNumberField;
