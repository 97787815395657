import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Radio } from 'components/primitives/form';
import { getIn, useFormikContext } from 'formik';

const OneRadioField = props => {
  const { values } = useFormikContext();
  const formikValue = getIn(values, props.fieldName);
  return (
    <Field
      as={Radio}
      {...props}
      checked={formikValue === props.value}
    />
  );
};

OneRadioField.propTypes = fieldPropTypes;

export default OneRadioField;